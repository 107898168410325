import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { of } from 'rxjs';
// ti le 9:4 1080:480
// target: '_blank', neu muon mo tab moi
const db = {
  normal: [
    {
      id: '11',
      href: '/b/tuHsH9',
      src: '/frontend-assets/banners/tinh-doi-ta-nhu-giao-mua-xuan-ha.png',
      alt: 'Tình đôi ta như giao mùa xuân hạ - Yang Nana',
    },
    {
      id: '2',
      href: '/b/S7Tli9ikc',
      src: '/frontend-assets/banners/loi-thi-tham-cua-binh-minh.png',
      alt: 'Lời thì thầm của bình minh - Cẩm Thương',
    },
    {
      id: '3',
      href: '/b/cZ-Edz',
      src: '/frontend-assets/banners/neu-duoc-quay-lai-de-gap-em.png',
      alt: 'Nếu được quay lại để gặp em - Cẩm Thương',
    },
    {
      id: '4',
      href: '/b/fwQzoe',
      src: '/frontend-assets/banners/pha-duyen-che-tinh.webp',
      alt: 'Pha duyên chế tình - POUROURFATE - Mộc (Talia)',
    },
    {
      id: '5',
      href: '/b/zzd7OZ',
      src: '/frontend-assets/banners/vu-khuc-bo-cong-anh.png',
      alt: 'Vũ khúc bồ công anh - TrinaJane',
    },
    {
      id: '6',
      href: '/b/kQrzmu',
      src: '/frontend-assets/banners/tieng-vi-cam.png',
      alt: 'Tiếng vĩ cầm trong đêm giông bão - Trần Thư Ân',
    },
  ] as any,
  adult: [
    {
      id: '1',
      href: '/b/S7Tli9ikc',
      src: '/frontend-assets/banners/loi-thi-tham-cua-binh-minh.png',
      alt: 'Lời thì thầm của bình minh - Cẩm Thương',
    },
    {
      id: '2',
      href: '/b/cZ-Edz',
      src: '/frontend-assets/banners/neu-duoc-quay-lai-de-gap-em.png',
      alt: 'Nếu được quay lại để gặp em - Cẩm Thương',
    },
    {
      id: '7',
      href: '/b/Gmra_0',
      src: '/frontend-assets/banners/cui-minh-hon-em.png',
      alt: 'Cúi mình hôn em - Meeanleen',
    },
    {
      id: '88',
      href: '/b/uVvkrz',
      src: '/frontend-assets/banners/do-choi-cua-dai-tieu-thu.png',
      alt: 'Đồ chơi của đại tiểu thư - Ayato Yuri',
    },
    {
      id: '88',
      href: '/b/ylaAX3Hxp',
      src: '/frontend-assets/banners/trung-khuc.png',
      alt: 'Trung khúc dưới tà huân - Yang nana',
    },
  ] as any,

};

@Injectable({ providedIn: 'root' })
export class SliderApi {
  constructor(private apollo: Apollo) { }

  normalBanner() {
    const shuffledDesktop = [...db.normal]; // Create a copy of the original array
    for (let i = shuffledDesktop.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [shuffledDesktop[i], shuffledDesktop[j]] = [shuffledDesktop[j], shuffledDesktop[i]]; // Swap elements
    }
    return shuffledDesktop // Return the shuffled array
  }

  adultBanner() {
    const shuffledDesktop = [...db.adult]; // Create a copy of the original array
    for (let i = shuffledDesktop.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [shuffledDesktop[i], shuffledDesktop[j]] = [shuffledDesktop[j], shuffledDesktop[i]]; // Swap elements
    }
    return shuffledDesktop // Return the shuffled array
  }

}
