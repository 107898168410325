import { Injectable, inject } from '@angular/core';
import { AuthFacade, CurrentUserFacade } from '@awread/core/users';
import { environment } from '@awread/global/environments';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { DevtoolService, UniversalService, UserInteractOnBrowserService } from '@awread/kernel/services';
import { PlausibleService, LazyInjectService } from '@awread/kernel/services';
// import { ServiceWorkerService } from './service-worker.service.js';
import { SnackbarService } from '@awread/global/packages';
import { ReaderLoggedStarter } from './reader-logged.starter.js';
import { DOCUMENT } from '@angular/common';
@Injectable({ providedIn: 'root' })
export class ReaderStarter {
  // serviceWorkerService = inject(ServiceWorkerService);
  document = inject(DOCUMENT);
  currentUserFacade = inject(CurrentUserFacade);
  universalService = inject(UniversalService);
  snackbarService = inject(SnackbarService);
  plausibleService = inject(PlausibleService);
  userInteractOnBrowserService = inject(UserInteractOnBrowserService);
  authFacade = inject(AuthFacade);
  readerLoggedStarter = inject(ReaderLoggedStarter);
  async removeServiceWorker() {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        registration.unregister();
      }
    } catch (error) {
      console.warn('service worker could not be unregistered', error);
    }
  }



  start() {
    if (this.universalService.isBrowser()) {
      this.startPerformance();
      this.checkToken();
      if ('serviceWorker' in navigator) {
        try {
          this.removeServiceWorker();
          // console.log('service worker ready!');
        } catch (error) {
          console.warn('service worker cannot start...', error);
        }
      }
    }
  }

  checkToken() {
    if (localStorage.getItem('accessToken')) {
      this.fetchUserBasicData();
    } else {
      this.watchUserAuthentication();
    }
  }

  private watchUserAuthentication() {
    return this.currentUserFacade
      .onUserIdChanges()
      .pipe(
        distinctUntilChanged(),
        // tap((c) => console.log('userId change?', c))
      )
      .subscribe(async (userId) => {
        if (userId && userId.length) {
          // NOTE: lazy load service
          // https://netbasal.com/lazy-load-services-in-angular-bcf8eae406c8
          localStorage.setItem('userId', userId);
          this.readerLoggedStarter.loggedStarter();
        } else {
          // if not have information, we need clear all data
          console.log('not have any information');
        }
      });
  }

  private fetchUserBasicData() {
    this.currentUserFacade.fetchUserData().subscribe({
      next: () => {
        this.watchUserAuthentication();
      },
      error: (error) => {
        console.warn('authentication failed message:', error.message);
        console.warn('authentication failed error:', error);
        if (error.message.includes('401')) {
          this.snackbarService.showWarning('Đăng nhập lại bạn nhé!');
          this.authFacade.logout();
        } else {
          this.snackbarService.showWarning(error.message);
        }
      },
    });
  }

  private startOnInteract() {
    this.userInteractOnBrowserService.onInteract().subscribe((res) => {
      // console.log('user interact...');
      if (environment.production) {
        // this.devtoolService.start();
        window.console.log = window.console.warn = () => { };
      }
    });
  }

  async devtoolDebug() {
    const search = this.document.location.search;
    console.log('search', search);
    if (search.includes('debug') || search.includes('DEBUG')) {
      console.log('eruda init', search);
      const eruda = await import('eruda');
      eruda.default.init();
      console.log('url', search, this.document.location);

    }
  }

  private startPerformance() {
    // this.performanceService.requestIdleCallback(() => {
    // console.log('app stable, we start low priority task here');
    // NOTE: starter should not in here, if here we need use ngZone to solve the dialog problem, cannot close, open since dialog not run in zone
    // this.serviceWorkerService.start();
    this.plausibleService.start();
    this.startOnInteract();
    this.devtoolDebug();
    // });
  }
}
