import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LdJsonService {
  scriptType = 'application/ld+json';

  constructor(@Inject(DOCUMENT) private _document: Document) { }

  websiteSchema(url?: string, name?: string) {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: url || 'https://awread.vn',
      name: name || 'awread.vn',
      sameAs: ['https://facebook.com/awread.vn', 'https://instagram.com/awreadbook'],
    };
  }

  articleSchema(data: { title: string; images: string[]; createdAt: string; updatedAt: string; name: string; username: string }) {
    return {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      headline: data.title,
      image: data.images,
      datePublished: data.createdAt,
      dateModified: data.updatedAt,
      author: [
        {
          '@type': 'Person',
          name: data.name,
          url: `https://awread.vn/a/${data.username}`,
        },
      ],
    };
  }

  orgSchema() {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://awread.vn',
      logo: 'https://awread.vn/frontend-assets/logos/logo.webp',
      name: 'Awread',
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'admin@awread.vn',
        contactType: 'Customer service',
      },
    };
  }

  removeStructuredData(): void {
    const els: any[] = [];
    ['structured-data', 'structured-data-org'].forEach((c) => {
      els.push(...Array.from(this._document.head.getElementsByClassName(c)));
    });
    els.forEach((el) => this._document.head.removeChild(el));
  }

  insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
    let script: any;
    let shouldAppend = false;
    if (this._document.head.getElementsByClassName(className).length) {
      script = this._document.head.getElementsByClassName(className)[0];
    } else {
      script = this._document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = this.scriptType;
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this._document.head.appendChild(script);
    }
  }
}
