<section class="relative h-full bg-white rounded w-full ">
  <div class="flex justify-center flex-col h-full pb-2 px-4 md:px-8">
    <div class="py-2 flex flex-col items-center justify-center">
      <div class="py-4">
        <img class="h-16 md:h-20" src="/frontend-assets/images/tick.webp" />
      </div>
      <p class="text-primary font-bold md:text-xl animate-pulse text-center">{{ data.message }}</p>
    </div>
    <div class="flex justify-center py-4">
      <button (click)="accept()"
        class="cursor-pointer px-8 py-1 focus:outline-none shadoww border-primary rounded text-primary bg-white hover:text-white hover:bg-primary font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100 ">
        OK
      </button>
    </div>
  </div>
</section>