export const readerSiteMap: ISitemapTag[] = [
  {
    customUrl: '/',
    title:
      'Nền tảng đọc truyện online và tự do viết, dành cho tác giả Việt với 100% doanh thu cho tác giả. Trở thành tác giả và bắt đầu hành trình sáng tạo của riêng mình nào!',
    // Trang web truyện Online mới nhất, đọc truyện ngôn tình hay, truyện full, truyện teen, truyện đam mỹ liên tục cập nhật mỗi ngày, truyện gì cũng có.
    // description: 'Đọc truyện online, đọc truyện chữ, truyện hay, truyện full. Truyện Full luôn tổng hợp và cập nhật các chương truyện một cách nhanh nhất.',
    description: 'đọc truyện, đọc tiểu thuyết, tác giả viết truyện, cuộc thi viết',
    image: 'https://awread.vn/frontend-assets/banners/reader-banner-home.webp',
  },
  {
    customUrl: '/introduction',
    title: 'Giới thiệu về nền tảng Awread',
    description:
      'Awread xây dựng môi trường kết nối đơn glản, thuận tiện giữa tác giả và độc giả. Đồng hành cùng phong trào phát triển vàn hóa đọc có bản quyền tại Việt Nam',
    image: null,
  },
  {
    customUrl: '/blogs',
    title: 'Tin tức mới nhất về Awread',
    description: 'Cập nhật những tin tức mới về các tính năng, các bài hướng dẫn cho tác giả, và nhiều thông tin thú vị khác về Awread',
    image: null,
  },
];

export interface ISitemapTag {
  customUrl: string;
  title: string;
  description: string;
  image: string | null;
}
