import { DepositCompletedComponent } from './deposit-completed/deposit-completed.component.js';
import { Injectable } from '@angular/core';
import { ConfirmComponent } from './confirm/confirm.component.js';
import { UniversalService } from '@awread/kernel/services';
import { DialogService } from '@ngneat/dialog';
import { ToastService } from '../toast/toast.service.js';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private toast: ToastService,
    private dialogService: DialogService,
    private universalService: UniversalService,
  ) { }

  // showDialog(component: any, options?: ToastOptions<any>) {
  showFrictionLess(component: any) {
    // return this.toast.show(component, options);
    return this.dialogService.open(component, {
      backdrop: false,
    });
  }

  openConfirm(question = 'Bạn có chắc?', yes = 'Có', no = 'Không') {
    return this.dialogService.open(ConfirmComponent, {
      data: { question, yes, no },
      id: Math.random().toString(36).substring(2, 9),
    });
  }

  openConfirmBook(question = 'Bạn có chắc?', additionData?: { description?: string, yes?: string, no?: string }, bookPrice?: { price: number, percent: number, isDiscount: boolean, currentPrice: number, wallet$: any }) {
    return this.dialogService.open(ConfirmComponent, {
      data: { question, additionData, bookPrice },
    });
  }

  showSuccess(message: string = 'Thành công!', customOption = {}) {
    if (this.universalService.isServer()) {
      return console.info(`server success:` + message);
    }
    return this.toast.showToast(message, 'success', customOption);
  }

  showError(message: string = 'Có lỗi xảy ra!!!', customOption = { duration: 5000 }) {
    if (this.universalService.isServer()) {
      return console.error(`server error:` + message);
    }
    return this.toast.showToast(message, 'error', customOption);
  }

  showWarning(message: string = 'Có cảnh báo!', customOption = { duration: 3000 }) {
    if (this.universalService.isServer()) {
      return console.warn(`server warning:` + message);
    }
    return this.toast.showToast(message, 'warning', customOption);
  }

  showLiveNotification(message: any) {
    return this.dialogService.open(DepositCompletedComponent, {
      data: { message },
    });
  }
}
