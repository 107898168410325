import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DynamicTabLayoutOrgan } from '@awread/global/design-system';

@Component({
  standalone: true,
  selector: 'awread-paying',
  imports: [DynamicTabLayoutOrgan],
  templateUrl: './paying.layout.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayingLayout implements OnInit {
  tabs: Record<string, any> = {
    quest: {
      icon: '',
      title: 'Nhiệm vụ',
      tabId: 'quest',
      // image: '/frontend-assets/images/ic_grade_24px.webp',
      image: '/frontend-assets/images/quest.webp',
    },
    coin: {
      icon: '',
      title: 'Coins',
      tabId: 'coin',
      // image: '/frontend-assets/icons/ic_monetization_on_24px.webp',
      image: '/frontend-assets/images/bread_2.webp',
    },
    'donate-history': {
      icon: '',
      title: 'Ủng hộ',
      tabId: 'donate-history',
      // image: '/frontend-assets/images/Group_726.webp',
      image: '/frontend-assets/images/money-bag.webp',
    },
    'payment-history': {
      icon: '',
      title: 'Lịch sử',
      tabId: 'payment-history',
      // image: '/frontend-assets/images/ic_history_24px.webp',
      image: '/frontend-assets/images/clock.webp',
    },
  };
  constructor() { }

  ngOnInit(): void { }
}
