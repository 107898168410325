import { Injectable } from '@angular/core';
import { NotificationsFacade } from '@awread/core/notifications';
import { SettingFacade } from '@awread/core/setting';
import { CurrentUserFacade } from '@awread/core/users';
import { domainEnvironment } from '@awread/global/environments';
import { take } from 'rxjs/operators';
import { ChaptersFacade } from '@awread/core/chapters';
import { DialogService } from '@ngneat/dialog';
import { DepositsFacade } from '@awread/core/deposit';
import { CartsFacade } from '@awread/core/carts';
import { QuestsFacade } from '@awread/core/quests';
import { DevtoolService, UserInteractOnBrowserService } from '@awread/kernel/services';
import { NotiAwreadUpdateDialog } from 'libs/global/design-system/src/lib/atomics/dialogs/noti-awread-update/noti-awread-update.dialog.js';
import { PopupLuckyMoneyOrgan } from '@awread/global/design-system';
@Injectable({ providedIn: 'root' })
export class ReaderLoggedStarter {
  constructor(
    private currentUserFacade: CurrentUserFacade,
    private notificationsFacade: NotificationsFacade,
    private settingFacade: SettingFacade,
    private dialogService: DialogService,
    private chaptersFacade: ChaptersFacade,
    private depositsFacade: DepositsFacade,
    private cartsFacade: CartsFacade,
    private questsFacade: QuestsFacade,
    private devtoolService: DevtoolService,
    private userInteractOnBrowserService: UserInteractOnBrowserService,
  ) { }

  loggedStarter() {
    this.checkHeath();
    this.showChangelogs();
    this.updateLastLogin();
    this.startOnInteract();

    this.notificationsFacade.getNotiDepositCompleted().subscribe();
    this.notificationsFacade.getAllNotifications().subscribe();
    // this.notificationsFacade.getAllNotiLuckyMoney().subscribe(() => {
    //   this.openAction();
    // });
    this.depositsFacade.getUserWallet().subscribe();
    // this.cartsFacade.getAllCarts().subscribe();
    this.questsFacade.getDailyCheckin().subscribe();
    // this.bookmarksFacade.getAllBookmarks().subscribe();
    // this.libraryBooksFacade.getAllLibraryBooks().subscribe();

    // this.eventRewardMoney(userId);
    // this.eventsFacade.myUsersEvents(userId).subscribe();
  }

  private updateLastLogin() {
    console.log('update last login');
  }

  private startOnInteract() {
    this.userInteractOnBrowserService.onInteract().subscribe((res) => {
      this.devtoolService.start();
    });
  }

  showChangelogs() {
    const newVersion = domainEnvironment.appVersion.split('-')[0].replaceAll('.', '');
    const currentVersion = localStorage.getItem('appVersion')?.replaceAll('.', '');
    const haveNewVersion = !!currentVersion && currentVersion.length > 0 && +newVersion > +currentVersion;
    if (!currentVersion && haveNewVersion) {
      localStorage.setItem('appVersion', newVersion);
      return;
    }
    if (haveNewVersion) {
      this.chaptersFacade.fetchChangelog('28be393b-6bbe-4b9c-8f37-672e172119d2').subscribe(async ({ book, chapter }) => {
        if (book && chapter) {
          const dialogRef = this.dialogService.open(NotiAwreadUpdateDialog, {
            data: {
              description: book.description,
              link: `/b/GYlepn/read?chapterNid=${chapter.chapterNid}`,
            },
            width: 'auto',
            minHeight: '200px',
          });
          dialogRef.afterClosed$.subscribe((result: any) => {
            localStorage.setItem('appVersion', newVersion);
          });
        } else {
          console.warn('changelog not found');
        }
      });
      return;
    } else {
      // Hi are you new, we only save the appversion data
      localStorage.setItem('appVersion', newVersion);
    }
  }

  checkHeath() {
    if (this.currentUserFacade.currentUserQuery.getValue().username === 'hiepxanh') {
      this.settingFacade.checkHeath();
    }
  }

  openAction() {
    const haveDialogNoti = this.currentUserFacade.currentUserQuery.getValue().haveDialogNoti;
    const getCountLuckyMoneyNoti = this.notificationsFacade.luckyMoneyNotificationsQuery.getCount();
    if (haveDialogNoti && getCountLuckyMoneyNoti > 0) {
      localStorage.setItem('totalLuckyMoney', getCountLuckyMoneyNoti.toString());
      const totalLuckyMoney = localStorage.getItem('totalLuckyMoney') as string;
      this.openDialog(+totalLuckyMoney);
    }
    // localStorage.removeItem('totalLuckyMoney');
  }

  openDialog(total: number) {
    this.notificationsFacade.luckyMoneyNotis$
      .pipe(take(1))
      .subscribe(async (luckyMoneyNotis: any) => {
        const dialogRef = this.dialogService.open(PopupLuckyMoneyOrgan, {
          enableClose: false,
          data: {
            firstTotalLuckyMoney: total,
            currentTotalLuckyMoney: luckyMoneyNotis.length,
            noti: luckyMoneyNotis[0],
          },
        });
        dialogRef.afterClosed$.subscribe((result: any) => {
          if (result) {
            this.notificationsFacade.updateNotification(result.notificationId).subscribe(() => {
              this.notificationsFacade.getAllNotiLuckyMoney().subscribe((newluckyMoneyNotis) => {
                if (!newluckyMoneyNotis.length) {
                  localStorage.removeItem('totalLuckyMoney');
                  this.currentUserFacade.updateHaveDialogNoti(false).subscribe();
                } else {
                  this.openDialog(total);
                }
              });
            });
          }
        });
      })
      .unsubscribe();
  }
}
