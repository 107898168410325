import { SliderApi } from './../apis/slider.api.js';
import { Injectable } from '@angular/core';
import { SliderStore } from '../states/slider/index.js';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SliderGear {
  constructor(private sliderStore: SliderStore, private sliderApi: SliderApi) { }

  normalBanner() {
    return this.sliderApi.normalBanner()
  }

  adultBanner() {
    return this.sliderApi.adultBanner()
  }
}
