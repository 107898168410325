<section class="relative h-full bg-white rounded w-full md:w-120">
  <div class="flex justify-center flex-col h-full pb-2">
    <div *ngIf="data.firstTotalLuckyMoney > 1;else singleLuckyMoney">
      <div class="text-primary font-bold text-xl py-1.5 md:py-3 text-center">
        {{data.firstTotalLuckyMoney - data.currentTotalLuckyMoney + 1}}/{{data.firstTotalLuckyMoney}}
      </div>
    </div>
    <ng-template #singleLuckyMoney>
      <div class="py-1.5 md:py-3"></div>
    </ng-template>
    <div class="font-bold text-xl md:text-2xl text-accent px-4 md:px-8 text-center">Chúc mừng bạn đã nhận được 1 phong bao lì xì từ Awread</div>
    <div class="absolute right-10 top-24 hidden md:block">
      <img src="/frontend-assets/images/peach.webp" />
    </div>
    <div class="py-2 flex flex-col items-center justify-center">
      <img class="h-32 md:h-48" src="/frontend-assets/images/luckyMoney.webp" />
      <p class="text-primary font-bold md:text-lg animate-pulse">Bạn nhận được 10.000 Coin</p>
    </div>
    <div class="flex justify-between items-center">
      <img class="h-40 hidden md:block" src="/frontend-assets/images/tet.webp" />
      <div>
        <button (click)="accept()"
          class="ml-6 md:ml-0 mt-6 md:mt-20 cursor-pointer px-10 py-1 focus:outline-none shadoww border-primary rounded text-primary bg-white hover:text-white hover:bg-primary font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100">
          Nhận
        </button>
      </div>
      <img class="h-28 md:h-40" src="/frontend-assets/images/tiger-tet.webp" />
    </div>
  </div>
</section>