import { Pipe, PipeTransform } from '@angular/core';
import { s3ClientUrl } from '@awread/kernel/tools';

@Pipe({
  name: 'avatarSize',
  standalone: true,
})
export class AvatarSizePipe implements PipeTransform {

  //LEARN: Pipe tool
  transform(user: { userId: any; avatar: string; updatedAt: any }, ...args: any[]): any {
    const size = args[0] ?? (globalThis as any)['isMobile'] ? 'xl' : 'xxl';
    // console.log('avatarsize', user && user.userId && user.avatar, user)
    if (user && user.userId && user.avatar) {
      return `${s3ClientUrl}/users/${size}/${user.userId}.webp?updatedAt=${user.avatar}`;
    }
    return '/frontend-assets/logos/avatar.webp';
  }
}
