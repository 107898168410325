import { ISitemapTag } from './reader-sitemap.js';

export const writerSiteMap: ISitemapTag[] = [
  {
    customUrl: '/',
    title:
      'Nền tảng đọc truyện online và tự do viết, dành cho tác giả Việt với 100% doanh thu cho tác giả. Trở thành tác giả và bắt đầu hành trình sáng tạo của riêng mình nào',
    description: `Awread giúp tác giả xây dựng cửa hàng và tiếp cận tới hàng triệu độc giả`,
    image: 'https://awread.vn/frontend-assets/banners/writer-banner-home.webp',
  },
];
