import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, ViewChild, ViewContainerRef, ChangeDetectorRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { TitleAtom } from '../../atoms/title/title.atom.js';
import { InputFormProfileAtom } from '../../atoms/input-form-profile/input-form-profile.atom.js';
import { SelectProfileAtom } from '../../atoms/select-profile/select-profile.atom.js';
import { BtnRectangleAtom } from '../../atoms/btn-rectangle/btn-rectangle.atom.js';
import { BoxMultipleGenreAtom } from '../../atoms/box-multiple-genre/box-multiple-genre.atom.js';

@Component({
  standalone: true,
  selector: 'molec-profile-info-form',

  imports: [CommonModule, ReactiveFormsModule, TitleAtom, InputFormProfileAtom, BoxMultipleGenreAtom, SelectProfileAtom, BtnRectangleAtom],
  templateUrl: './profile-info-form.molec.html',
  styleUrls: ['./profile-info-form.molec.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileInfoFormMolec {
  fb = inject(FormBuilder);
  @Input() isReader = false;
  @Input() currentGenres!: any;
  class = ' cursor-pointer rounded-full text-white border-primary font-semibold py-1 md:py-2 px-6';
  @Input() faIcon = faChevronDown;
  @Input() genres: any[] = [];
  @Input() audiences = [
    { name: '13+', id: 2 },
    { name: '16+', id: 3 },
    { name: '18+', id: 4 },
    // { name: '21+', id: 5 },
  ];
  @Input() connectGg = {
    title: 'Kết nối bằng google',
    image: '/frontend-assets/images/google.webp',
  };
  @Output() submitEvent = new EventEmitter();
  @Input() optionalForm: FormGroup = this.fb.group({
    firstname: [null],
    middlename: [null],
    lastname: [null],
    bio: [null],
    websiteAddress: [null],
    facebookAddress: [null],
    dob: [null],
  });
  @Input() experienceForm: FormGroup = this.fb.group({
    gender: [null],
    genreIds: [[]],
  });
  @Input() submitted = false;
  @Input() inputControl = new FormControl(null);
  @Input() inputFormItems = [
    {
      title: 'Tên',
      formControlName: 'firstname',
    },
    {
      title: 'Tên đệm',
      formControlName: 'middlename',
    },
    {
      title: 'Họ',
      formControlName: 'lastname',
    },
    {
      title: 'Liên kết',
      formControlName: 'websiteAddress',
      placeholder: 'https://awread.vn',
    },
    {
      title: 'Facebook',
      formControlName: 'facebookAddress',
      placeholder: 'https://facebook.com/awread.vn',
    },
    {
      title: 'Email',
      formControlName: 'emailAddress',
      placeholder: 'awread@gmail.com',
    },
    {
      title: 'Zalo',
      formControlName: 'zaloAddress',
      placeholder: 'https://zalo.com/awread.vn',
    },
    {
      title: 'Beta truyện',
      formControlName: 'betaBook',
    },
    {
      title: 'Làm bìa',
      formControlName: 'cover',
    },
    {
      title: 'Biên tập',
      formControlName: 'editor',
    },
  ];

  @Input() selectItems = {
    title: 'Giới tính',
    formControlName: 'gender',
    class: 'pl-4 pr-6 py-1 md:py-2',
    options: [
      {
        value: 0,
        text: 'Nam',
      },
      {
        value: 1,
        text: 'Nữ',
      },
      {
        value: 2,
        text: 'Khác',
      },
    ],
  };
  @ViewChild('datepickerTemplate', { read: ViewContainerRef }) datepickerTemplate!: ViewContainerRef;

}
