import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SliderGear } from '../gears/slider.gear.js';
import { SliderQuery } from '../states/slider/index.js';

@Injectable({ providedIn: 'root' })
export class SliderFacade {
  constructor(private sliderGear: SliderGear, private sliderQuery: SliderQuery) { }

  normalBanner() {
    return this.sliderGear.normalBanner()
  }

  adultBanner() {
    return this.sliderGear.adultBanner()
  }
}
